
import React from "react";

//Font is Myriad Pro Bold and Myriad Pro Italic
export default function SwissEcLogo({className}) {
  return (
    <svg viewBox="0 0 91.534683 9.8530521" xmlns="http://www.w3.org/2000/svg" className={className}>
        <title>Swiss Energy-Charts</title>
        <g transform="translate(-53.339996,-153.50371)">
            <path d="m 53.339996,160.67919 c 0.402165,0.22225 1.206496,0.4445 2.02141,0.4445 1.957911,0 2.868075,-1.016 2.868075,-2.21191 0,-1.00542 -0.582082,-1.66158 -1.841495,-2.12725 -0.93133,-0.34925 -1.322912,-0.55033 -1.322912,-1.00541 0,-0.35983 0.338666,-0.68792 1.037163,-0.68792 0.687915,0 1.206496,0.20109 1.481662,0.33867 l 0.359832,-1.29116 c -0.423332,-0.20108 -1.005413,-0.35983 -1.820327,-0.35983 -1.672161,0 -2.698741,0.92074 -2.698741,2.13782 0,1.03717 0.783164,1.69333 1.968493,2.11666 0.857248,0.29633 1.195913,0.55033 1.195913,0.99483 0,0.46567 -0.391582,0.77258 -1.132413,0.77258 -0.687914,0 -1.365246,-0.22225 -1.788578,-0.4445 z"/>
            <path d="m 58.589289,155.84262 1.513411,5.17523 h 1.513412 l 0.518582,-1.82032 c 0.127,-0.47625 0.232833,-0.96309 0.328082,-1.60867 h 0.02117 c 0.105833,0.64558 0.201083,1.11125 0.338665,1.61925 l 0.486832,1.80974 h 1.502829 l 1.587495,-5.17523 h -1.576912 l -0.433915,2.05316 c -0.127,0.59266 -0.232833,1.18533 -0.306916,1.76741 h -0.02117 c -0.09525,-0.59266 -0.232832,-1.16416 -0.380998,-1.75683 l -0.518582,-2.06374 h -1.269996 l -0.550332,2.13783 c -0.116416,0.50799 -0.275165,1.09008 -0.370415,1.68274 h -0.03175 c -0.08467,-0.59266 -0.190499,-1.16416 -0.285749,-1.69333 L 60.2297,155.84262 Z"/>
            <path d="m 68.759828,161.01785 v -5.17523 h -1.608662 v 5.17523 z m -0.793748,-7.41889 c -0.518582,0 -0.857247,0.34925 -0.857247,0.80433 0,0.4445 0.328082,0.80433 0.83608,0.80433 0.539749,0 0.867831,-0.35983 0.867831,-0.80433 -0.01058,-0.45508 -0.328082,-0.80433 -0.846664,-0.80433 z"/>
            <path d="m 69.754641,160.76385 c 0.391582,0.21167 0.99483,0.37042 1.672161,0.37042 1.481662,0 2.233076,-0.70908 2.233076,-1.68275 -0.01058,-0.75141 -0.412749,-1.25941 -1.396995,-1.59807 -0.634998,-0.22225 -0.836081,-0.34925 -0.836081,-0.60325 0,-0.254 0.222249,-0.41275 0.613831,-0.41275 0.433916,0 0.888997,0.16933 1.12183,0.29633 l 0.275166,-1.11124 c -0.317499,-0.15875 -0.846664,-0.30692 -1.449912,-0.30692 -1.280579,0 -2.106077,0.73025 -2.106077,1.70391 -0.01058,0.61383 0.402166,1.19591 1.481662,1.55575 0.592665,0.20108 0.751414,0.32808 0.751414,0.60324 0,0.26459 -0.201082,0.42334 -0.687914,0.42334 -0.476249,0 -1.09008,-0.20109 -1.386412,-0.381 z"/>
            <path d="m 74.347773,160.76385 c 0.391582,0.21167 0.99483,0.37042 1.672161,0.37042 1.481662,0 2.233076,-0.70908 2.233076,-1.68275 -0.01058,-0.75141 -0.412749,-1.25941 -1.396995,-1.59807 -0.634998,-0.22225 -0.836081,-0.34925 -0.836081,-0.60325 0,-0.254 0.222249,-0.41275 0.613831,-0.41275 0.433916,0 0.888997,0.16933 1.12183,0.29633 l 0.275166,-1.11124 c -0.317499,-0.15875 -0.846664,-0.30692 -1.449912,-0.30692 -1.280579,0 -2.106077,0.73025 -2.106077,1.70391 -0.01058,0.61383 0.402166,1.19591 1.481662,1.55575 0.592665,0.20108 0.751414,0.32808 0.751414,0.60324 0,0.26459 -0.201082,0.42334 -0.687914,0.42334 -0.476249,0 -1.09008,-0.20109 -1.386412,-0.381 z"/>
            <path d="M 85.671878,156.68928 H 83.04722 v -1.48166 h 2.783407 v -1.32291 h -4.392069 v 7.13314 h 4.540236 v -1.32291 H 83.04722 v -1.69333 h 2.624658 z"/>
            <path d="m 87.026525,161.01785 h 1.608661 v -2.98449 c 0,-0.14816 0.01058,-0.29633 0.05292,-0.40216 0.116416,-0.29634 0.380998,-0.60325 0.825497,-0.60325 0.582081,0 0.814914,0.45508 0.814914,1.12183 v 2.86807 h 1.608662 v -3.05857 c 0,-1.524 -0.793748,-2.23308 -1.852078,-2.23308 -0.86783,0 -1.386412,0.49742 -1.598078,0.83608 h -0.03175 l -0.07408,-0.71966 h -1.396996 c 0.02117,0.46566 0.04233,1.00541 0.04233,1.65099 z"/>
            <path d="m 97.800308,158.94353 c 0.02117,-0.11642 0.05292,-0.35984 0.05292,-0.635 0,-1.28058 -0.634998,-2.58233 -2.307159,-2.58233 -1.799161,0 -2.614075,1.44992 -2.614075,2.76224 0,1.61925 1.005413,2.63525 2.762241,2.63525 0.698498,0 1.344079,-0.10584 1.873244,-0.32809 l -0.211666,-1.09008 c -0.433915,0.14817 -0.878414,0.22225 -1.428746,0.22225 -0.751414,0 -1.418162,-0.3175 -1.460495,-0.98424 z m -3.344323,-1.10067 c 0.04233,-0.42333 0.317499,-1.04774 0.99483,-1.04774 0.740831,0 0.910164,0.65616 0.910164,1.04774 z"/>
            <path d="m 98.816304,161.01785 h 1.608666 v -2.62466 c 0,-0.127 0.0106,-0.25399 0.0318,-0.35983 0.10583,-0.49741 0.50799,-0.81491 1.10066,-0.81491 0.17991,0 0.30691,0.0212 0.43391,0.0423 v -1.51341 c -0.11641,-0.0212 -0.1905,-0.0317 -0.33866,-0.0317 -0.508,0 -1.13241,0.3175 -1.397,1.0795 h -0.0423 l -0.0635,-0.9525 h -1.375829 c 0.03175,0.4445 0.04233,0.94191 0.04233,1.70391 z"/>
            <path d="m 107.65334,157.38778 c 0,-0.77258 0.0212,-1.2065 0.0423,-1.54516 h -1.39699 l -0.0529,0.62441 h -0.0212 c -0.26458,-0.43391 -0.70908,-0.74083 -1.41816,-0.74083 -1.28058,0 -2.34949,1.05833 -2.34949,2.73049 0,1.48167 0.91016,2.51883 2.19074,2.51883 0.59267,0 1.09008,-0.24342 1.397,-0.66675 h 0.0212 v 0.32808 c 0,0.96308 -0.58208,1.36525 -1.34408,1.36525 -0.61383,0 -1.17474,-0.20108 -1.50282,-0.39158 l -0.3175,1.22766 c 0.46566,0.254 1.17474,0.39158 1.86266,0.39158 0.76199,0 1.53458,-0.14816 2.09549,-0.635 0.59267,-0.51858 0.79375,-1.33349 0.79375,-2.32832 z m -1.60866,1.32291 c 0,0.13759 -0.0106,0.30692 -0.0529,0.43392 -0.10583,0.37041 -0.43392,0.64558 -0.85725,0.64558 -0.66675,0 -1.04774,-0.60325 -1.04774,-1.37583 0,-0.94191 0.46566,-1.47108 1.05833,-1.47108 0.44449,0 0.75141,0.28575 0.86783,0.70908 0.0212,0.0953 0.0318,0.20109 0.0318,0.29634 z"/>
            <path d="m 108.36241,155.84262 1.90499,4.74132 c 0.0529,0.127 0.0635,0.20108 0.0635,0.26458 0,0.0635 -0.0318,0.14817 -0.0847,0.23283 -0.14817,0.254 -0.42334,0.51858 -0.66675,0.64558 -0.24342,0.13759 -0.49742,0.23284 -0.6985,0.27517 l 0.34925,1.35466 c 0.40216,-0.0423 0.99483,-0.22225 1.54516,-0.70908 0.58208,-0.51858 1.0795,-1.33349 1.82033,-3.39724 l 1.20649,-3.40782 h -1.71449 l -0.64558,2.53999 c -0.0741,0.30692 -0.15875,0.68792 -0.22225,0.97366 h -0.0423 c -0.0635,-0.27516 -0.16934,-0.66674 -0.254,-0.96308 l -0.77258,-2.55057 z"/>
            <path d="m 114.07736,157.70528 v 1.06891 h 2.77282 v -1.06891 z"/>
            <path d="m 122.31115,160.06536 c -0.39158,0.20108 -0.98425,0.30691 -1.524,0.30691 -1.58749,0 -2.16957,-1.04775 -2.16957,-2.32832 0,-1.0795 0.42333,-2.08491 1.09008,-2.70933 0.55033,-0.51858 1.30174,-0.80433 2.04257,-0.80433 0.635,0 1.143,0.13758 1.40758,0.29633 l 0.28575,-0.70908 c -0.1905,-0.14816 -0.75141,-0.34925 -1.54516,-0.34925 -1.04775,0 -1.95791,0.33867 -2.68816,0.96308 -0.98425,0.81492 -1.52399,2.0955 -1.52399,3.43958 0,1.82032 1.09008,2.96332 2.88924,2.96332 0.80433,0 1.44991,-0.16933 1.85207,-0.34925 z"/>
            <path d="m 124.21613,161.01785 0.47625,-2.56116 c 0.21166,-1.15358 1.06891,-1.94732 1.74624,-1.94732 0.5715,0 0.79375,0.37041 0.79375,0.84666 0,0.28575 -0.0318,0.51858 -0.0635,0.70908 l -0.5715,2.95274 h 0.87841 l 0.5715,-2.99507 c 0.0529,-0.26458 0.0847,-0.59267 0.0847,-0.86783 0,-1.016 -0.68792,-1.37583 -1.27,-1.37583 -0.77258,0 -1.39699,0.40216 -1.83091,1.02658 h -0.0212 l 0.63499,-3.30199 h -0.87841 l -1.42875,7.51414 z"/>
            <path d="m 133.20133,161.01785 c -0.0212,-0.5715 0.0529,-1.48166 0.22225,-2.37066 l 0.51858,-2.66699 c -0.34924,-0.11641 -0.88899,-0.20108 -1.37582,-0.20108 -2.23308,0 -3.54541,1.88383 -3.54541,3.66182 0,0.97366 0.5715,1.69333 1.47108,1.69333 0.73025,0 1.42874,-0.381 1.98966,-1.43933 h 0.0212 c -0.0635,0.52917 -0.11642,1.016 -0.11642,1.32291 z m -0.53974,-2.92099 c -0.254,1.36525 -1.12183,2.31774 -1.85208,2.31774 -0.67733,0 -0.87842,-0.56091 -0.87842,-1.13241 0.0106,-1.41816 1.09008,-2.80457 2.413,-2.80457 0.29633,0 0.48683,0.0423 0.61383,0.0741 z"/>
            <path d="m 135.39207,161.01785 0.42333,-2.25424 c 0.22225,-1.19591 0.89958,-2.11666 1.66158,-2.11666 0.0952,0 0.17992,0.0106 0.23283,0.0212 l 0.16933,-0.86783 c -0.0635,-0.0106 -0.16933,-0.0212 -0.24341,-0.0212 -0.68792,0 -1.23825,0.52916 -1.56633,1.22766 h -0.0317 c 0.0529,-0.39158 0.0952,-0.75141 0.13758,-1.11124 h -0.77258 c -0.0529,0.47624 -0.13758,1.15358 -0.26458,1.82032 l -0.62441,3.30199 z"/>
            <path d="m 139.49837,154.95362 -0.17992,0.94192 h -0.73025 l -0.13758,0.68791 h 0.74083 l -0.508,2.59291 c -0.0741,0.37041 -0.10583,0.66675 -0.10583,0.96308 0,0.51858 0.29633,0.99483 1.0795,0.99483 0.254,0 0.51858,-0.0423 0.67733,-0.0952 l 0.0423,-0.6985 c -0.11642,0.0317 -0.26458,0.0423 -0.41275,0.0423 -0.33866,0 -0.48683,-0.19049 -0.48683,-0.49741 0,-0.26458 0.0423,-0.52917 0.0952,-0.81491 l 0.47625,-2.48708 h 1.22766 l 0.13758,-0.68791 h -1.22766 l 0.23283,-1.22767 z"/>
            <path d="m 141.26577,160.77444 c 0.23284,0.17991 0.71967,0.34925 1.27,0.35983 1.0795,0 1.95791,-0.61383 1.95791,-1.69333 0,-0.5715 -0.39158,-1.016 -0.96308,-1.34408 -0.45508,-0.254 -0.6985,-0.48683 -0.6985,-0.85725 0,-0.44449 0.381,-0.77258 0.91017,-0.77258 0.381,0 0.70908,0.127 0.88899,0.23284 l 0.24342,-0.66675 c -0.1905,-0.127 -0.60325,-0.254 -1.06891,-0.254 -1.0795,0 -1.85208,0.68791 -1.85208,1.58749 0,0.51859 0.32808,0.97367 0.92075,1.30175 0.52916,0.29633 0.70908,0.53975 0.70908,0.94191 0,0.45509 -0.381,0.83608 -0.97367,0.83608 -0.41275,0 -0.85724,-0.16933 -1.10066,-0.31749 z"/>
        </g>
    </svg>
  );
}
