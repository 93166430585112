import React from "react";
import ECLogo from "./svgs/ecLogo";
import { useTranslation } from "../hooks/customHooks";
import SwissEcLogo from "./svgs/swissEcLogo";
import EnergySchweizLogo from "./misc/energieSchweizLogo.js"
const Root_URL = window.Root_URL;
const { lang, country } = JSON.parse(sessionStorage.getItem("locale"));
export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="sect_container ">
        <div className="footerContent">
          <div className="footerAbout">
            {
              country=="CH" &&
              <div className="logo-row">
                  <EnergySchweizLogo className="logo-column" lang={lang}/>
              </div>
            }
          </div>
          <div className="footerNav">
            <a href={`${Root_URL}index.html`}>
            {country=="CH" && <SwissEcLogo className="brandLogoSwiss"/> || <ECLogo className="brandLogoWhite"/>}
            </a>
            <div className="footerPrivacy">
              <div>
                <a
                  href={`${Root_URL}data-protection.html`}
                  className={country != "CH" ? "dark-bg-link" : undefined}
                >
                  {t("data-protection")}
                </a>
              </div>
              <div>
                <a
                  href={`${Root_URL}publishing-notes.html`}
                  className={country != "CH" ? "dark-bg-link" : undefined}
                >
                  {t("publishing-notes")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
