import React from "react";

//Font is Myriad Pro Bold and Myriad Pro Italic
export default function ECLogo({ className, logoDashClass }) {
  return (
    <svg
      id="Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 221.05 34.34"
      className={className}
    >
      <title>Energy-Charts</title>
      <path d="M14.79,15.67H5.64v5.9H15.86v4.61H0V1.33H15.34V5.94H5.64V11.1h9.15Z" />
      <path d="M19.51,13.9c0-2.25-.07-4.17-.15-5.75h4.87l.26,2.47h.11a6.6,6.6,0,0,1,5.57-2.88c3.69,0,6.45,2.43,6.45,7.75v10.7H31v-10c0-2.32-.81-3.91-2.84-3.91a3,3,0,0,0-2.84,2.1,3.85,3.85,0,0,0-.22,1.4v10.4H19.51Z" />
      <path d="M45.43,19c.18,2.32,2.47,3.43,5.09,3.43a15.89,15.89,0,0,0,5-.74l.74,3.8a17.79,17.79,0,0,1-6.53,1.11c-6.12,0-9.63-3.54-9.63-9.18,0-4.57,2.84-9.63,9.11-9.63,5.83,0,8,4.54,8,9A13.33,13.33,0,0,1,57,19ZM52,15.12c0-1.36-.59-3.65-3.17-3.65-2.36,0-3.32,2.14-3.47,3.65Z" />
      <path d="M60.58,14.09c0-2.66-.07-4.39-.15-5.94h4.83l.18,3.32h.15a5.3,5.3,0,0,1,4.87-3.73,5.78,5.78,0,0,1,1.18.07v5.27a8,8,0,0,0-1.51-.15,3.6,3.6,0,0,0-3.84,2.84A6.62,6.62,0,0,0,66.19,17v9.15H60.58Z" />
      <path d="M91.52,8.15c-.07,1.14-.15,2.66-.15,5.35V23.57c0,3.47-.7,6.31-2.73,8.11a11.13,11.13,0,0,1-7.34,2.21,14.06,14.06,0,0,1-6.49-1.4l1.11-4.24a11.1,11.1,0,0,0,5.24,1.36c2.66,0,4.68-1.44,4.68-4.76V23.68h-.07A5.77,5.77,0,0,1,80.9,26c-4.46,0-7.63-3.61-7.63-8.81,0-5.79,3.76-9.48,8.19-9.48a5.44,5.44,0,0,1,4.94,2.54h.07l.18-2.14Zm-5.75,7.34a4.44,4.44,0,0,0-.11-1,3.07,3.07,0,0,0-3-2.47c-2,0-3.69,1.84-3.69,5.13,0,2.69,1.33,4.79,3.69,4.79a3,3,0,0,0,3-2.29,4.76,4.76,0,0,0,.18-1.47Z" />
      <path d="M100.07,8.15,102.77,17c.29,1.07.66,2.4.88,3.36h.11c.26-1,.55-2.32.81-3.36l2.21-8.85h6L108.59,20c-2.58,7.15-4.31,10-6.34,11.84a10.34,10.34,0,0,1-5.42,2.47l-1.18-4.76a7.06,7.06,0,0,0,2.43-1,6.44,6.44,0,0,0,2.32-2.25,1.47,1.47,0,0,0,.29-.81,2,2,0,0,0-.26-.92L93.84,8.15Z" />

      <g className={"logoDash"}>

        <path d="M123.42,14.64v3.72h-9.66V14.64Z" />{" "}
      </g>
      <path d="M142.85,25.37a16.35,16.35,0,0,1-6.45,1.18c-6.27,0-10.07-3.95-10.07-10.29a15.48,15.48,0,0,1,5.31-12A14.08,14.08,0,0,1,141,.92a10,10,0,0,1,5.38,1.22l-1,2.47a10.23,10.23,0,0,0-4.9-1,10.5,10.5,0,0,0-7.12,2.8,13.06,13.06,0,0,0-3.8,9.44c0,4.46,2,8.11,7.56,8.11a12.52,12.52,0,0,0,5.31-1.07Z" />
      <path d="M146,26.19,151,0h3.06l-2.21,11.51h.07a7.67,7.67,0,0,1,6.38-3.58c2,0,4.43,1.25,4.43,4.79a16.47,16.47,0,0,1-.29,3l-2,10.44h-3.06l2-10.29a14.6,14.6,0,0,0,.22-2.47c0-1.66-.77-3-2.77-3-2.36,0-5.35,2.77-6.09,6.79l-1.66,8.93Z" />
      <path d="M177.54,26.19a46,46,0,0,1,.41-4.61h-.07c-2,3.69-4.39,5-6.93,5-3.13,0-5.13-2.51-5.13-5.9,0-6.2,4.57-12.76,12.35-12.76a16.65,16.65,0,0,1,4.79.7l-1.81,9.29a40.86,40.86,0,0,0-.77,8.26Zm2-15.56a8.26,8.26,0,0,0-2.14-.26c-4.61,0-8.37,4.83-8.41,9.77,0,2,.7,3.95,3.06,3.95,2.54,0,5.57-3.32,6.45-8.08Z" />
      <path d="M185,26.19l2.18-11.51c.44-2.32.74-4.68.92-6.34h2.69c-.15,1.25-.29,2.51-.48,3.87h.11c1.14-2.43,3.06-4.28,5.46-4.28a6.1,6.1,0,0,1,.85.07l-.59,3a4.33,4.33,0,0,0-.81-.07c-2.66,0-5,3.21-5.79,7.38L188,26.19Z" />
      <path d="M205.64,3.54l-.92,4.79H209l-.48,2.4h-4.28l-1.66,8.67a15.64,15.64,0,0,0-.33,2.88,1.5,1.5,0,0,0,1.7,1.7,8.5,8.5,0,0,0,1.44-.11l-.15,2.4a7.11,7.11,0,0,1-2.36.33c-2.73,0-3.76-1.66-3.76-3.47a16.56,16.56,0,0,1,.37-3.36l1.77-9h-2.58l.48-2.4h2.54l.77-4Z" />
      <path d="M209.33,23.09a8.12,8.12,0,0,0,3.83,1.11c2.07,0,3.39-1.33,3.39-2.91,0-1.4-.63-2.25-2.47-3.28-2.07-1.14-3.21-2.73-3.21-4.54,0-3.13,2.69-5.53,6.45-5.53a7.42,7.42,0,0,1,3.72.89l-.85,2.32a6.38,6.38,0,0,0-3.1-.81c-1.84,0-3.17,1.14-3.17,2.69,0,1.29.85,2.1,2.43,3,2,1.14,3.36,2.69,3.36,4.68,0,3.76-3.06,5.86-6.82,5.86a7.83,7.83,0,0,1-4.43-1.22Z" />
    </svg>
  );
}
