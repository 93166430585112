import React from "react";
import { Html5Entities } from "html-entities";
const htmlEntities = new Html5Entities();

function getImagePathAndTitle(lang) {
  const rootUrl = window.Root_URL;
  switch (lang) {
    case "de":
      return {
        //"url": `${rootUrl}img/EnergieSchweiz/de/EnergieSchweiz-Logo-Positiv-mit_Unterstuetzung_von-DE.jpg`,
        "url": `${rootUrl}img/EnergieSchweiz/de/ECH_Logo_Unterstuetzung_DE_RGB.jpg`,
        "title": "EnergieSchweiz"
      };
    case "en":
    default:
      return {
        //"url": `${rootUrl}img/EnergieSchweiz/en/EnergieSchweiz-Logo-Positiv-mit_Unterstuetzung_von-EN.jpg`,
        "url": `${rootUrl}img/EnergieSchweiz/en/ECH_Logo_Support_EN_RGB.jpg`,
        "title": "SwissEnergy"
      };    
    case "fr":
      return {
        //"url": `${rootUrl}img/EnergieSchweiz/fr/EnergieSchweiz-Logo-Positiv-mit_Unterstuetzung_von-FR.jpg`,
        "url": `${rootUrl}img/EnergieSchweiz/fr/ECH_Logo_Soutien_FR_RGB.jpg`,
        "title": htmlEntities.decode("Suisse&Eacute;nergie")
      };
    case "it":
      return {
        //"url": `${rootUrl}img/EnergieSchweiz/it/EnergieSchweiz-Logo-Positiv-mit_Unterstuetzung_von-IT.jpg`,
        "url": `${rootUrl}img/EnergieSchweiz/it/ECH_Logo_Sostegno_IT_RGB.jpg`,
        "title": "SvizzeraEnergia"
      };
  }
}

function logoOnclick() {
  window.open('https://www.energieschweiz.ch/');
}


export default function EnergySchweizLogo({ className , lang}) {

  const image_info = getImagePathAndTitle(lang);
  return (
    <div className={className ? className : undefined}>
      <img src={image_info.url} title={image_info.title} onClick={logoOnclick}/>
    </div>
  );
}
