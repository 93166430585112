import React, { Component } from "react";
import ReactDOM from "react-dom";
import Footer from "../comps/footer";

class App extends Component {
  render() {
    return <Footer />;
  }
}

ReactDOM.render(<App />, document.getElementById("footer"));
