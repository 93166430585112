import { Html5Entities } from "html-entities";
const htmlEntities = new Html5Entities();

function useTranslation() {
  const localeObj = sessionStorage.getItem("locale");
  const locale = JSON.parse(localeObj);
  const { lang, country } = locale;

  function t(key, _lang=null, _logTranslationMissingWarning = true) {

    if (_lang === null) _lang = lang;

    try {
      // the dictionary is included in the dependency_bundle.js; so it should be available at this point
      // just make sure the dependency_bundle.js is included before the other JS scripts, but after "lang.js"
      return htmlEntities.decode(window.dictionary[key][_lang]); // try to return translation
    } catch (error) {
      // if translation is missing, log the error and return the id instead of the translation
      if (isDevelopmentVersion && _logTranslationMissingWarning) console.log(`Missing translation: ${key}`);
      return key;
    }
  }

  return {
    t,
    locale,
  };
}

export { useTranslation };